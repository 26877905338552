<template>
  <div>
    <b-container class="my-5" v-show="!massage">
      <b-card v-show="!success">
        <div class="text-center">
          <h3>Reset Password</h3>
        </div>
        <b-row>
          <b-col lg="6" sm="10" class="m-auto">
            <label class="form-label pl-2 pt-2"
              >{{ $t("accountSettings.label.newPassword") }}
              <b-tooltip
                target="passwordTips"
                placement="right"
                triggers="hover"
                variant="primary"
                >{{ $t("register.label.passwordInfo") }}</b-tooltip
              >
              <b-icon id="passwordTips" icon="info-circle"></b-icon>
            </label>
            <b-input-group>
              <template #append>
                <b-input-group-text class="bg-white right-rounded"
                  ><b-icon :icon="eyes" @click="showPassword()"></b-icon
                ></b-input-group-text>
              </template>
              <b-form-input
                :type="passwordField"
                v-model="newPassword"
                :state="passwordValidate"
                @input="inputCheck()"
                class="form-control left-rounded"
              ></b-form-input>
            </b-input-group>
            <div class="m-auto pt-4">
              <b-button
                pill
                variant="primary"
                class="w-100 "
                @click="handleSubmit()"
              >
                Reset Password
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-show="success">
        <b-card-text class="text-center">
          <vac
            ref="resetPassword_countdown"
            :autoStart="false"
            :left-time="5 * 1000"
            @finish="finish()"
          >
            <template v-slot:process="{ timeObj }">
              <h3 class="text-success">
                Password has been changed sucesssfully
              </h3>
              <h6>
                {{ `You will be redirect within ${timeObj.s} seconds!` }}
              </h6>
            </template>
            <template v-slot:finish>
              <h3>You are now redirect!</h3>
            </template>
          </vac>
        </b-card-text>
      </b-card>
    </b-container>
    <b-container v-show="massage" class="p-5 text-center">
      <b-card>
        <h3>
          {{ massage }}
        </h3>
        <b-card-text class="text-center">
          <vac
            ref="error"
            :autoStart="false"
            :left-time="10 * 1000"
            @finish="finish()"
          >
            <template v-slot:process="{ timeObj }">
              <h6>
                {{ `You will be redirect within ${timeObj.s} seconds!` }}
              </h6>
            </template>
            <template v-slot:finish>
              <h3>You are now redirect!</h3>
            </template>
          </vac>
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      newPassword: "",
      passwordField: "password",
      eyes: "eye",
      passwordRule: /^.*(?=.{6,20})(?=.*\d)(?=.*[A-Z]).*$/,
      passwordValidate: null,
      massage: null,
      success: false
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.newPassword || this.passwordValidate === false) {
        this.passwordValidate = false;
        return false;
      }
      await axios.post("user/ResetPasswordReset", {
        EmailToken: this.$route.params.id,
        Password: this.newPassword
      });
      this.startCountdown();
      this.success = true;
    },
    showPassword() {
      if (this.passwordField === "password") {
        (this.passwordField = "text"), (this.eyes = "eye-slash");
      } else if (this.passwordField === "text") {
        (this.passwordField = "password"), (this.eyes = "eye");
      }
    },
    inputCheck() {
      if (this.newPassword) {
        if (this.passwordRule.test(this.newPassword)) {
          this.passwordValidate = true;
        } else {
          this.passwordValidate = false;
        }
      }
      if (!this.newPassword) {
        this.passwordValidate = null;
      }
    },
    startCountdown() {
      const vm = this;
      vm.$refs.resetPassword_countdown.startCountdown(true);
    },
    finish() {
      this.$router.push({ name: "Home" });
      this.$bvModal.show("signin");
    },
    async verify() {
      try {
        await axios.post("User/ResetPasswordVerify", {
          EmailToken: this.$route.params.id
        });
      } catch (error) {
        this.$refs.error.startCountdown(true);
        this.massage = error.response.data;
        console.log(error.response.data);
      }
    }
  },
  async mounted() {
    console.log(this.$route.params.id);
    this.verify();
  },
  beforeCreate() {
    document.getElementById("footer").style.display = "none";
    document.getElementById("socket").style.display = "none";
  },
  beforeDestroy() {
    document.getElementById("footer").style.display = "block";
    document.getElementById("socket").style.display = "block";
  }
};
</script>
